// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

@import "bootstrap";
@import "bootstrap/scss/bootstrap";

html {
    /* 
     * For more information on dynamic font scaling, visit the documentation:
     * https://ionicframework.com/docs/layout/dynamic-font-scaling
     */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
  }