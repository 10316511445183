/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

@import "@ionic/angular/css/palettes/dark.always.css";
@import "@ionic/angular/css/palettes/dark.class.css";
@import "@ionic/angular/css/palettes/dark.system.css";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
// regular style toast
@import 'ngx-toastr/toastr';

// if you'd like to use it without importing all of bootstrap it requires
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr-bs5-alert';

ion-toolbar {
    --background: #e62929;
}

ion-content {
    --background: #110808;
}

ion-toolbar ion-title {
    text-align: center;
    color: #f2f1f6;
}

ion-toolbar ion-icon {
    color: #f2f1f6;
}

.section {
    margin: 20px;
}

.modal-footer {
    button.btn {
        width: auto !important;
        height: auto !important;
    }
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    html,
    body {
        font-size: 12px;
        visibility: visible !important;
        width: 100%;
    }
}